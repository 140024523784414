body {
  font-family: Arial, sans-serif;
  margin: 20px;
  background-color: var(--primary-bg-color);
}
.MainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.StopBtn {
}
h1 {
  text-align: center;
  color: #f1f1f1;
}

input {
  border: none;
  border-radius: 25px;
  width: 460px;
  height: 30px;
  font-size: 13px;
}

div {
  color: #f1f1f1;
}

/* textarea {
  width: 100%;
} */
.scrambleResults span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pkeyexample {
  font-size: 10px;
}

.buttons {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* CSS  generate*/
.GenerateBtn {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.GenerateBtn:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .GenerateBtn {
    padding: 0.25em 0.75em;
  }
}

/*INPUT*/
.InputKey {
  max-width: 350px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 10px;
}

.InputKey:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}
.ClearBtn {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  background-color: #d9534f;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 0;
}
.pkeyexamples {
  margin-top: 10px;
}

.pkeyexample-label {
  font-size: 14px;
  font-weight: bold;
}

.pkeyexample-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pkeyexample {
  font-family: monospace;
  font-size: 10px;
  color: #f2f2f2;
  margin-right: 10px;
}

.copyIcon {
  cursor: pointer;
  font-size: 10px;
  color: #007bff;
}

.copyIcon:hover {
  color: #0056b3;
}

.ErrorMessage {
  position: sticky;
  top: 50%;
  color: red;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  display: inline-block;
  transition: opacity 0.5s ease-out;
}
.scrambleResultItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.StopBtn {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.StopBtn:hover {
  background-color: darkred;
}
