/* App.css */
html{
  background-color: var(--primary-bg-color);
  margin: 0;
  padding: 0;
}
body {
  /* background-color: #f0f0f0; */
  margin: 0;
  padding: 0;
  height:min-content; 
  font-family: sans-serif;
}

.content {
  background-color: #f5f5f5; 
}


.App header {

  background-color: #f0f0f0; 
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.App main {
  flex: 1; 
  padding: 20px; 
}
