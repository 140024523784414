.home {
  background-color: var(--primary-bg-color);
  padding: 20px;
  text-align: center;
  color: #f2f2f2;
}

.home h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.home a {
  color: #f2f2f2;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  box-sizing: border-box;
  position: relative; /* Permite transformarea elementului doar pe buton */
  will-change: transform; /* Optimizare pentru performanță */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.home a:hover {
  transform: translateY(-5px); /* Mărește butonul prin mutarea acestuia în sus */
  background-color: #3b4e6b;
}

.home .download-link {
  font-size: 20px;
  margin-right: 50px;
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.home .download-link.downloading {
  background-color: #6c757d;
  cursor: not-allowed;
  color: #fff;
}


.announce {
  font-size: 15px;
  color: #a9a9a9;
}

.home-header .logo-small {
  animation: slideInR 1s ease-out forwards;
}

.liw {
  animation: slideInL 1s ease-out forwards;
}

/* Animație logo */
@keyframes slideInR {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInL {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
